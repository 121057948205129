import { signOut } from "aws-amplify/auth";
import logo from "../navbar_logo.png";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function TopNavbar() {
  const handleLogout = async () => {
    try {
      await signOut();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Navbar expand="lg" variant="dark" className="top-navbar">
      <Container>
        <Navbar.Brand
          href="https://www.lucite.ai/"
          style={{ position: "fixed", left: 50 }}
        >
          <img
            src={logo}
            width="120"
            height="30"
            className="d-inline-block align-top"
            alt="MyAnalyst Logo"
          />
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav style={{ position: "fixed", left: 190 }}>
            <Nav className="ms-md-auto">
              <Nav.Link style={{ marginRight: 15 }} href="/demo">
                Demo
              </Nav.Link>
              <Nav.Link style={{ marginRight: 15 }} href="/toolkit">
                Toolkit
              </Nav.Link>
              <Nav.Link style={{ marginRight: 15 }} href="/enterprise">
                Enterprise
              </Nav.Link>
            </Nav>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{ position: "fixed", right: 50 }}>
            <Nav className="ms-md-auto">
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              {/* <Nav.Link href="https://www.lucite.ai/#pricing">
                Subscriptions
              </Nav.Link> */}
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
