import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import React from "react";

import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import LoginPage from "./LoginPage";
import DemoPage from "./components/demo/DemoPage";
import StudioPage from "./components/studio/StudioPage";
import SandboxPage from "./components/sandbox/SandboxPage";

Amplify.configure(awsExports);

function App() {
  const { user } = useAuthenticator();
  if (user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<DemoPage />} />
          <Route path="/toolkit" element={<SandboxPage />} />
          <Route path="/enterprise" element={<StudioPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return <LoginPage />;
}

export default App;
