import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import { tailChase } from "ldrs";
import { getCurrentDate } from "../Utils.js";

function BottomNavbar(props) {
  console.log(props.studioFormData.powerPointType);
  // capitalize first letter of every word
  const pptTypeToFileNameChunk = () => {
    const pptTypeChunks = props.studioFormData.powerPointType.split("_");
    let outputString = "";
    pptTypeChunks.forEach((chunk) => {
      let updatedChunk = chunk[0].toUpperCase() + chunk.slice(1);
      outputString = outputString + "_" + updatedChunk;
    });
    // remove leading _
    return outputString.slice(1);
  };

  const getPowerpointFileName = () => {
    if ("pptx" in props.outputFileNamesMapping) {
      return props.outputFileNamesMapping.pptx;
    }
    if (props.studioFormData.powerPointType === "industry_newsletter") {
      return "Stepmark Weekly Market Update_".concat(
        getCurrentDate().replaceAll("-", "."),
        ".pptx"
      );
    }
    if (props.studioFormData.companyName !== "") {
      return props.studioFormData.companyName.concat(
        "_",
        pptTypeToFileNameChunk(),
        "_",
        getCurrentDate().replaceAll("-", "."),
        ".pptx"
      );
    } else {
      return pptTypeToFileNameChunk().concat(
        "_",
        getCurrentDate().replaceAll("-", "."),
        ".pptx"
      );
    }
  };

  const getPDFFileName = () => {
    if ("pdf" in props.outputFileNamesMapping) {
      return props.outputFileNamesMapping.pdf;
    }
    if (props.studioFormData.powerPointType === "industry_newsletter") {
      return "Stepmark Weekly Market Update_".concat(
        getCurrentDate().replaceAll("-", "."),
        ".pdf"
      );
    }
    if (props.studioFormData.companyName !== "") {
      return props.studioFormData.companyName.concat(
        "_",
        pptTypeToFileNameChunk(),
        "_",
        getCurrentDate().replaceAll("-", "."),
        ".pdf"
      );
    } else {
      return pptTypeToFileNameChunk().concat(
        "_",
        getCurrentDate().replaceAll("-", "."),
        ".pdf"
      );
    }
  };

  const getExcelFileName = () => {
    if ("xlsx" in props.outputFileNamesMapping) {
      return props.outputFileNamesMapping.xlsx;
    }
    if (props.studioFormData.powerPointType === "industry_newsletter") {
      return "Stepmark Weekly Market Update_".concat(
        getCurrentDate().replaceAll("-", "."),
        "_Backup.xlsx"
      );
    } else if (props.studioFormData.powerPointType === "industry_financials") {
      return "Communitas Capital Industry Financials_".concat(
        getCurrentDate().replaceAll("-", "."),
        "_Backup.xlsx"
      );
    } else {
      return getCurrentDate().replaceAll("-", ".").concat("_Backup.xlsx");
    }
  };

  const getWordDocFileName = () => {
    if ("docx" in props.outputFileNamesMapping) {
      return props.outputFileNamesMapping.docx;
    }
    return "Communitas Capital News Update_".concat(
      getCurrentDate().replaceAll("-", "."),
      ".docx"
    );
  };

  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  tailChase.register();

  return (
    <Navbar expand="lg" className="bottom-navbar">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "30px",
          width: "100vw",
          paddingRight: "60px",
        }}
      >
        {props.excelBackupUrl !== "" && (
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={() => {
              const fileName = getExcelFileName();
              downloadFile(props.excelBackupUrl, fileName);
            }}
            style={{
              width: "180px",
            }}
          >
            Download Excel
          </Button>
        )}
        {props.docxUrl !== "" && (
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={() => {
              const fileName = getWordDocFileName();
              downloadFile(props.docxUrl, fileName);
            }}
            style={{
              width: "180px",
            }}
          >
            Download Word Doc
          </Button>
        )}
        {props.pptxUrl !== "" && (
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={() => {
              const fileName = getPowerpointFileName();
              downloadFile(props.pptxUrl, fileName);
            }}
            style={{
              width: "160px",
            }}
          >
            Download PowerPoint
          </Button>
        )}
        {props.pdfUrl !== "" && (
          <Button
            size="sm"
            variant="primary"
            type="button"
            onClick={() => {
              const fileName = getPDFFileName();
              downloadFile(props.pdfUrl, fileName);
            }}
            style={{
              width: "160px",
            }}
          >
            Download PDF
          </Button>
        )}
      </div>
    </Navbar>
  );
}

export default BottomNavbar;
